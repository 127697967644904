import React from 'react';
import PropTypes from 'prop-types';

import {Image} from './Image';
import FullScreenContainer from './FullScreenContainer';
import FullScreenImage from './FullScreenImage';
import timeline from './timelineComponent';
import {tabbable} from 'utils/accessibility';
import {showElemWithAria, makeElementTabbable, unmakeElementTabbable} from 'utils/animation';

import 'styles/components/text-mask.scss';


const propTypes = {
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  maskImage: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};


class TextMask extends React.Component {
  render() {
    const {backgroundImage, maskImage, text, alt, aspectRatio, children, className = ''} = this.props;

    return (
      <div className={`text-mask ${className}`}>
        <FullScreenContainer className="container">
          <FullScreenImage
            image={backgroundImage}
            alt={alt || text}
            showAria={this.props.showAria}
            aspectRatio={aspectRatio || 8/5}
          >
            <div className="mask-text">
              <div className="text-holder" ref="textContainer">
                <div className="text-holder__bg">
                  <h1 className="text-mask__h1">{text}</h1>
                </div>
                <div className="text-holder__text" ref="maskedText" style={{backgroundImage: `url(${maskImage})`}}>
                  <h1 className="text-mask__h1" ref="text">{text}</h1>
                  <div className="text-mask__children">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </FullScreenImage>
        </FullScreenContainer>
      </div>
    );
  }
}

TextMask.propTypes = propTypes;

const animation = ({context, timeline}) => {
  const {container, maskedText, textContainer, text} = context.refs;

  const {height: textHeight} = maskedText.getBoundingClientRect();
  const startValue = textHeight/2 + window.innerHeight / 2;
  const endValue = -(textHeight/2 + window.innerHeight / 2);

  const movement = {
    y: startValue,
  };

  TweenMax.set(textContainer, {y: startValue, ...showElemWithAria});
  maskedText.style.backgroundPosition = `center calc(50% - ${startValue}px)`;

  const updater = () => {
    textContainer.style.transform = `translateY(${movement.y}px)`;
    maskedText.style.backgroundPosition = `center calc(50% - ${movement.y}px)`;
    textContainer.blur();
  };

  timeline.to(movement, 1.5, {y: 0, onUpdate: updater, ease: Expo.easeOut});
  timeline.to(text, 0.01, {...makeElementTabbable}, '-=1.5');

  timeline.to(movement, 1.5, {y: endValue, onUpdate: updater, ease: Expo.easeInOut});
  timeline.to(text, 0.01, {...unmakeElementTabbable}, '-=0.01');
};

export default timeline(TextMask, {animation});
