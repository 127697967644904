import React from 'react';
import {graphql} from 'gatsby';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import Layout from '../components/Layout';
import Image from '../components/Image';
import FullScreenContainer from '../components/FullScreenContainer';
import TextMask from 'components/TextMask';
import FtueModal from '../components/FtueModal';
import '../styles/pages/home.scss';

import TwitterImg from '../img/landing/twitter.svg';
import FbImg from '../img/landing/facebook.svg';
import LflLogo from '../img/landing/lfl-logo.svg';

import {FacebookShare, TwitterShare} from 'components/social';


const HomePage = ({data, location}) => {
  const {
    bgImg,
    bgImgRed,
    logo1,
    logo2,
    logo3,
    logo4,
  } = data;

  const bgShareUrl = `${location.origin}${bgImg.childImageSharp.fluid.src}`;

  return (
    <Layout>
      <section className="home-page">
        <div className="container">

          <FullScreenContainer className="home-page__content">

            <TextMask
              className="home-page__content__text"
              backgroundImage={bgImg}
              maskImage={bgImgRed.childImageSharp.fluid.src}
              alt="homepage hero image"
              text="SELMA"
              position={0.5}

            >

              <FtueModal
                destination="/introduction"
                buttonEl={
                  <button
                    aria-label="Start Your Journey"
                    className="link-button"
                    onClick={() => {
                      trackCustomEvent({
                        category: 'buttons',
                        action: 'Click',
                        label: 'Start your Journey click',
                      });
                    }}>
                      Start Your Journey
                  </button>
                }
              />
            </TextMask>

          </FullScreenContainer>

          <div className="footer">
            <div className='social'>
              <FacebookShare />
              <TwitterShare />
            </div>
            <div className="logos">
              <a href="https://hutchinscenter.fas.harvard.edu" target="_blank" aria-label="Hutchins Center for African & African American Research">
                <img src={logo1.childImageSharp.fluid.src} alt="Hutchins Center for African & African American Research"/>
              </a>
              {/* <a href="https://www.splcenter.org/" target="_blank" aria-label="Southern Poverty Law Center">
                <img src={logo2.childImageSharp.fluid.src} alt="Southern Poverty Law Center"/>
              </a>*/}
              <a href="https://www.tolerance.org/" target="_blank" aria-label="Teaching Tolerance">
                <img src={logo3.childImageSharp.fluid.src} className="tt-logo" alt="Teaching Tolerance"/>
              </a>
              <a href="https://www.rockefellerfoundation.org/" target="_blank" aria-label="The Rockefeller Foundation">
                <img src={logo4.childImageSharp.fluid.src} className="tt-logo" alt="The Rockefeller Foundation"/>
              </a>
              <a href="https://www.leftfieldlabs.com/" target="_blank" aria-label="Left Field Labs">
                <LflLogo className="lfl-logo" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
      bgImg: file(relativePath: { eq: "landing/landing-background-bw.jpg" }) {
        ...largeImage
      }
      bgImgRed: file(relativePath: { eq: "landing/landing-background-red.jpg" }) {
        ...largeImage
      }
      logo1: file(relativePath: { eq: "landing/hutchins.png" }) {
        ...smallImage
      }
      logo2: file(relativePath: { eq: "landing/splc.png" }) {
        ...smallImage
      }
      logo3: file(relativePath: { eq: "landing/teaching-tolerance.png" }) {
        ...smallImage
      }
      logo4: file(relativePath: { eq: "landing/rockerfeller.png" }) {
        ...smallImage
      }
  }
`;

export default HomePage;
